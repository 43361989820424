import React from "react";
import { Link } from "gatsby";

import Layout from "../../components/layout";
import SmallBanner from "../../components/smallBanner";

const Contact = () => {
  const lang = "en";

  return (
    <Layout
      seoTitle="Contact us confirmation"
      lang={lang}
      translationPL="/kontakt/"
      translationDE="/de/kontakt"
    >
      <SmallBanner title="Contact us confirmation" lang={lang} />
      <section className="contact-main">
        <div className="text-center">
          <p>Thank you for sending message!</p>
          <Link className="btn" to="/en/" data-hover="Home page">
            Home page
            <span className="btn-arrows"></span>
          </Link>
        </div>
      </section>
    </Layout>
  );
};

export default Contact;
